import { localesList } from '@/constants/locales-list'
import { useSignalContext } from '@/contexts/signal'
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import GlobeIcon from 'icons/globe.svg'
import TickIcon from 'icons/tick.svg'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'

const LocaleSelector = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { t } = useTranslation('common')
  const { locale, savelocale } = useSignalContext()
  const [highlightedLocale, setHighlightedLocale] = useState(locale)

  useEffect(() => {
    setHighlightedLocale(locale)
  }, [isOpen, locale])

  const saveChangesAndClose = () => {
    savelocale(highlightedLocale)
    onClose()
  }

  const getLanguage = useMemo(() => {
    const selectedLocale = localesList.find(
      (localeItem) => localeItem.code === locale
    )
    return selectedLocale ? selectedLocale.lang : 'English'
  }, [locale])

  return (
    <Box>
      <Button onClick={onOpen} variant="ghost" display="flex" gap="2">
        <Icon boxSize="5" as={GlobeIcon} /> <span>{getLanguage} </span>
      </Button>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent maxW="42rem">
          <ModalHeader color="gray.800" fontWeight="bold">
            {t('locale-selector-header')}
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody py="10" px="8">
            <SimpleGrid columns={{ base: 2, md: 3 }} spacing="25px">
              {localesList.map((localeItem) => {
                return (
                  <Flex
                    as="button"
                    borderRadius="lg"
                    border={'1px solid'}
                    borderColor={
                      localeItem.code === highlightedLocale
                        ? 'gray.800'
                        : 'transparent'
                    }
                    key={localeItem.code}
                    flexDirection="column"
                    variant="outline"
                    px="4"
                    py="3"
                    onClick={() => setHighlightedLocale(localeItem.code)}
                    _hover={{
                      backgroundColor: 'gray.100'
                    }}
                  >
                    <Text color={'gray.800'}>{localeItem.lang}</Text>

                    <Flex
                      justify={'space-between'}
                      alignItems="center"
                      w="full"
                    >
                      <Text color={'gray.600'}>{localeItem.country}</Text>
                      {localeItem.code === highlightedLocale && (
                        <Icon color={'gray.800'} as={TickIcon} />
                      )}
                    </Flex>
                  </Flex>
                )
              })}
            </SimpleGrid>
          </ModalBody>
          <Divider />

          <ModalFooter justifyContent={'flex-end'} gap="5">
            <Button variant="outline" onClick={onClose} size="lg" minWidth="30">
              {t('cancel')}
            </Button>
            <Button
              minWidth="30"
              className="btn-primary"
              onClick={saveChangesAndClose}
              size="lg"
            >
              {t('save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default LocaleSelector
