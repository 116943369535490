import { PropertyDetailHeader } from '@/components/core/PropertyDetailHeader'
import useSignalPath from '@/hooks/useSignalPath'
import { ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  HStack,
  Image,
  Link,
} from '@chakra-ui/react'
import React from 'react'
import { PropertyCardTitle } from './PropertyCardTitle'


export default function PropertyCardFavorites({
  property,
  gridIndex,
  favorites,
  toggleFavorites,
  ...props
}) {
  const { name, slug, firstImage } = property
  const signalPath = useSignalPath()
  const href = signalPath(`/properties/${slug}`)

  return (
    <HStack
      rounded="md"
      paddingTop="1em"
      paddingBottom="1em"
      _hover={{ boxShadow: 'md' }}
      pos="relative"
      data-testid="favorites-property-card"
      {...props}
    >
      {firstImage.length ? (
        <Link href={href}>
          <Image src={firstImage} minHeight="66px" maxWidth="100px" rounded={'md'} alt={name} href={href} />
        </Link>
      ) : (
        <Center w="full" minH="130px" bg="gray.200" borderTopRadius="md">
          <ViewOffIcon color="gray.500" />
        </Center>
      )}
      <HStack p={3} pb="4">
        <Box>
          <PropertyCardTitle href={href} fontSize=".8rem">{name}</PropertyCardTitle>
          <PropertyDetailHeader
            property={property}
            size=".7rem"
            direction="column-reverse"
            spacing="0"
            pt="3"
          />
        </Box>
      </HStack>
    </HStack>
  )
}
