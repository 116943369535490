import { useSignalContext } from '@/contexts/signal'
import useSignalPath from '@/hooks/useSignalPath'
import { stripBasePath } from '@/lib/utils'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  Button,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import LocaleSelector from './LocaleSelector'
import { IoHeartOutline } from 'react-icons/io5'
import PropertyCardFavorites from './PropertyCardFavorites'
import { omitBy } from 'lodash'


const excludePaths = ['properties']

const titleize = (string) =>
  string
    .toLowerCase()
    .split('-')
    .join(' ')
    .replace(/(?:^|\s)\S/g, (x) => x.toUpperCase())

/**
 * Generates an array of breadcrumbs
 *
 * Receives the currentPath to guarantee SSR outputs the same as client-side
 *
 * @param {string} currentPath The path including the root slug
 * @param {string} router The router
 * @returns Breadcrumbs
 */
function generateBreadcrumbs({ standalone, token, rootSlug, currentPath }) {
  const queryParams = currentPath.split('?')[1]
  const strippedPath = stripBasePath({
    standalone,
    token,
    rootSlug,
    path: currentPath
  })

  const hasQuery = !!queryParams
  const pathNestedRoutes = strippedPath.split('/').filter((v) => v.length > 0)

  let prevHref = ''
  let crumblist = pathNestedRoutes.map((subpath) => {
    let href = prevHref + '/' + subpath
    prevHref = href

    if (hasQuery) {
      href = `${href}?${queryParams}`
    }

    const title = titleize(subpath)
    return {
      href,
      title,
      active: strippedPath === href.split('?')[0]
    }
  })

  const properties = crumblist.find((cl) => cl.title === 'Properties')
  if (properties && hasQuery) {
    properties.title = 'Search'
    properties.href = properties.href.replace('/properties', '/search')
  }

  crumblist = crumblist.filter(
    (c) => !excludePaths.includes(c.title.toLowerCase())
  )

  return [
    !standalone
      ? { href: '/', title: 'Home', skipBasePath: true }
      : { href: '/', title: 'Home', skipBasePath: false },
    !standalone
      ? { href: '/', title: titleize(rootSlug), active: strippedPath === '' }
      : null,
    ...crumblist
  ].filter((v) => v)
}

export default function Breadcrumbs({
  className,
  // favoritesProperties,
}) {
  const signalPath = useSignalPath()
  const currentPath = signalPath()
  const router = useRouter()
  const { rootSlug, token, standalone, favoritesProperties } = useSignalContext()

  const breadcrumbs = React.useMemo(
    () =>
      generateBreadcrumbs({ standalone, token, rootSlug, currentPath, router }),
    [currentPath, rootSlug, router, standalone, token]
  )
  const favoritesCount = favoritesProperties ? favoritesProperties.length : 0

  const navigate = () => {
    const queryParams = omitBy({}, (v) => !v)
    const href = signalPath('/favorites/', { query: queryParams })
    router.push(href)
  }

  return (
    <Box
      w="full"
      borderBottom="1px"
      borderTop="1px"
      borderColor="gray.200"
      id="breadcrumbs"
      className={className}
    >
      <Container maxW="container.2xl" px={5}>
        <Flex h={14} alignItems="center" direction="row-reverse" gap="25">
          {<LocaleSelector />}

          <Breadcrumb fontSize="sm">
            {breadcrumbs.map(({ href, title, active, skipBasePath }, idx) => (
              <BreadcrumbItem key={idx} isCurrentPage={active}>
                {active ? (
                  <BreadcrumbLink fontWeight="semibold">{title}</BreadcrumbLink>
                ) : (
                  <NextLink
                    href={skipBasePath ? href : signalPath(href)}
                    passHref
                  >
                    <BreadcrumbLink>{title}</BreadcrumbLink>
                  </NextLink>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
          {
            favoritesProperties && favoritesProperties.length > 0 && (
              <Popover trigger='hover'>
                <PopoverTrigger>
                  <Button onClick={navigate} fontSize=".8em"><IoHeartOutline fontSize="1.5em" /> My favorites</Button>
                </PopoverTrigger>
                <PopoverContent maxH='513px' overflowY='auto'>
                  <PopoverHeader paddingTop="1.5em" paddingBottom="1.5em">Favorites ({favoritesCount})</PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    {favoritesProperties.map((property) => (
                      <PropertyCardFavorites
                        key={property.id}
                        property={property}
                        onMouseDown={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                        cursor="default"
                      />
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )
          }
        </Flex>
      </Container>
    </Box>
  )
}
