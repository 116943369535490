import { useSignalContext } from '@/contexts/signal'
import { Link, Tooltip } from '@chakra-ui/react'
import NextLink from 'next/link'

export function PropertyCardTitle({ children, ...props }) {
  const { standalone } = useSignalContext()

  return (
    <NextLink href={props.href} passHref>
      <Link
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        display="block"
        fontSize="16px"
        className={standalone ? '' : 'property_card_title'}
        color="gray.800"
        _hover={{
          textDecordation: 'none'
        }}
        {...props}
      >
        <Tooltip label={children} openDelay={700}>
          {children}
        </Tooltip>
      </Link>
    </NextLink>
  )
}
