export const localesList = [
  {
    lang: 'English',
    country: 'United States',
    code: 'en-US'
  },
  {
    lang: 'English',
    country: 'Australia',
    code: 'en-AU'
  },
  {
    lang: 'English',
    country: 'Canada',
    code: 'en-CA'
  },
  {
    lang: 'English',
    country: 'Ireland',
    code: 'en-IE'
  },
  {
    lang: 'English',
    country: 'New Zealand',
    code: 'en-NZ'
  },
  {
    lang: 'English',
    country: 'United Kingdom',
    code: 'en-GB'
  }
]
